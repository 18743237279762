.remove-snap {
    scroll-snap-align: unset !important; /* Overrides 'snap-center' */
    scroll-snap-stop: unset !important; /* Overrides 'snap-stop' */
}

.ep-scroll-snap {
    overflow: auto;
    scroll-snap-type: y mandatory; 
    height:100vh;
    z-index:2;
    position: absolute;
}

.ep-scroll-snap > header {
    /*scroll-snap-stop: always;*/
scroll-snap-align: start;
}

/* new one */
.activeTop .fix-at-top{
    position: fixed;
    top:0;
    z-index:-1;
}

.activeTop.end .fix-at-top{
    position: absolute;
    bottom:0px
}
/* end new one*/

.App {
  text-align: center;
  
  height: 100vh;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
}

.App-link {
  color: #61dafb;
}

.title-mobile {
    font-size: 4.28vw;
}

.content-mobile {
    font-size: 3.3vw;
    color: white;
    letter-spacing: 1px;
}

.text-white {
    color:white;
    /* margin-bottom: 10px; */
    text-align:left;
}

.text-white-margin-bottom {
    margin-bottom: 10px;
}

.text-white-spacing {
    letter-spacing: 15px;
}

.text-spacing {
    letter-spacing: 10px;
}
.text-center {
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-left{
    font-weight: bold;
}

.hide {
    display: none !important;
}

.fixed {
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
}

.fixed1 * {
    position: fixed;
}

html {
    margin:0 !important;
    padding:0 !important;
    font-family: "Noto Serif TC", serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    scroll-snap-type: y mandatory;
    scroll-behavior:  smooth;
}
.scroll-snap {
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior:  smooth;
}
.snap-center {
    scroll-snap-align: start;
}
.snap-stop {
    scroll-snap-stop: always;
}
.letter-1-space {
    letter-spacing: 1px;
}
.yellow-header {
    font-size: 1.2rem;
    letter-spacing: 3px;
    font-weight:900
}
.white-header {
    font-size: 1.2rem;
    letter-spacing: 18px;
    text-align:center;
    font-weight:500;
}
.white-content {
    font-size: .8rem;
    letter-spacing:3px;
}
.white-content:lang(en-US) {
    letter-spacing: 1px;
  }
  
.product-name {
    font-family:'Times New Roman';
    font-weight: 100;
    font-size: 14px;
}
body {
    
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;   
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.p-rel {
    position: relative;
}
.p-abs {
    position: absolute;
}
.star {
    transition: 3s;
}

.keep_scaling {
    transform: scale(4) !important;
}

.star-zoom {
    transform: scale(2) !important;
}

.trans-05 {
    transition: 0.5s !important;
}
.trans-10 {
    transition: 1s !important;
}
.trans-15 {
    transition: 1.5s !important;
}
.trans-20 {
    transition: 2s !important;
}
.trans-25 {
    transition: 2.5s !important;
}
.trans-30 {
    transition: 3s !important;
}
.trans-35 {
    transition: 3.5s !important;
}
.trans-40 {
    transition: 4s !important;
}
.delay-05 {
    animation-delay: 0.5s !important;
    transition-delay: 0.5 !important;
}
.delay-10 {
    animation-delay: 1s !important;
    transition-delay: 1s !important;
}
.delay-15 {
    animation-delay: 1.5s !important;
    transition-delay: 1.5s !important;
}
.delay-20 {
    animation-delay: 2s !important;
    transition-delay: 2s !important;
}
.delay-25 {
    animation-delay: 2.5s !important;
    transition-delay: 2.5s !important;
}

.star.active {
    transform: scale(70);
}

.spin {
    animation: spin 5s infinite linear;
}

.bg {
    position: fixed;
    z-index:0;
    width:100%;
    height: 100%;
        background-size: cover !important;
    background-position: center !important;
}

.start-transparent > * {
    opacity: 0;
    transition: 1s;
}

.text-bulgari-yellow {
    color: #de8b02
}

.s-imgs {
    position: absolute;
}

.s1-imgs img {
 max-width: 80vw;
}
.s1-imgs .img1 {
    margin-bottom: 100px;
}

.s1-imgs .img2 {
    margin-top: 100px;
}

.s1-imgs.active .img1 {
    animation: fadeIn 1s forwards;    
    animation-delay: 0.5s;
}

.s1-imgs.active .img2 {
    animation: fadeIn 1s forwards;    
    animation-delay: 1s;
}

.s2-imgs.active .img1 {
    animation: fadeIn 1s forwards;    
}

#s4 {
    position: relative;
}

#s4.active video {
    opacity: 1;
}


@media (orientation: landscape) {

    #s4.active video {
        top: -100%;
    }
}

.full-video {
    left:0px;
    top: 0px;
    width: 100%;
}

.small-star {
    width: 40px;
    margin-top: -250px;
    left: calc(50% - 20px);
}

.bubble-animate {
    top: -100vh !important;
}
.bubble-fadeOut {
    
}
.product-list {
    /* display: flex;
    position: absolute;
    left:10vw;
    top: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80vw; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.product-list > div {
    display: flex;
    position: relative;
    width: 400px;
    transform: translateX(-300px);
    opacity: 0;
}

.product-list > div > img {
    height: 400px;
    object-fit: contain;
}

.active .product-list > div {
    animation: fadeInLeft 2s forwards;
}


.text-blur {
    filter: blur(2rem); /* Initial state */
    transition: 3s;
}
.active .text-blur {
    filter: blur(0px)
}


.text-fade {
    opacity: 0; /* Initial state */
    transition: 3s;
}
.active .text-fade {
    opacity: 1
}

#s5.active .text-fade {
    animation: fadeIn 5s forwards;
}
#s5.active .lines {
    transform: scale(3.5);
    transform-origin: left;
    opacity: 0.2;
}
#s5 .start-combo {
    left: -500px;
    transition: 1s;   
}
#s5.active .start-combo {
    left: 0px;
}


#s6.active .text-fade {
    animation: fadeIn 5s forwards;
}
#s6 video {
    filter: brightness(0.75);
}
#s6.ended video {
    opacity:0;
}
.s6-imgs video {
    opacity: 0;
    filter: brightness(0.75);
}
.s6-imgs.ended video {
    opacity: 1
}
#s6 .tubogas-img1 {
    opacity: 0;
    transform:translateY(800px);
    transition-duration: 1s;
    transition-delay: 0s;
}
#s6 .tubogas-img2 {
    opacity: 0;
    transform:translateY(750px);
    transition-duration: 1s;
    transition-delay: 0.5s;
}
#s6 .tubogas-img3 {
    opacity: 0;
    transform:translateY(600px);
    transition-duration: 1s;
    transition-delay: 1s;
}
#s6.active .tubogas-imgs {
    opacity: 1;
    transform:translateY(0px);
}
#s6.end .tubogas-imgs {
    transform:translateY(-400px);
}

.green-gradient-both {
    background-image: linear-gradient(to top, transparent, #014407 4%,#014407 96%, transparent);
}
.green-gradient {
    background-image: linear-gradient(to top, #014407,#014407, transparent);
}

.green-gradient-bottom {
    background-image: linear-gradient(to bottom, #014407,#014407,#014407, transparent);
}

.bulgari-green {
    background-color: #014407;
}
.bulgari-orange {
    background: #de8b02;
}

.s7.active {
    animation: fadeIn 1s forwards;    
    animation-iteration-count: infinite; /* or any number */
}

.fadeout-test {
    animation: fadeInLeft 3s forwards;
}


.divas-container-background-image {
    background-position: "";
}


@keyframes fadeInLeft { 
    0% {
        opacity: 0;
        transform: translateX(-200px);
    }
    100% { 
        opacity: 1;
        transform: translateX(0px);
    } 
}

@keyframes fadeout { 
    0% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
    } 
}

@keyframes fadeIn { 
    0% {
        opacity: 0;
    }
    100% { 
        opacity: 1;
    } 
}
@keyframes spin { 
    0% {
        transform:rotate(0deg); 
    }
    100% { 
         -webkit-transform: rotate(360deg); 
        transform:rotate(360deg);  
    } 
}
 

 
.s1_star.active {
  animation-name: star_fullscreen;
  animation-duration: 2s;

}

.test-container {
    width: 300px; 
    height: 300px;
    background-color: green;
    display: flex;
    justify-content: space-between;
}

.triangle-1 {
    transform: 1s;
}

.active.triangle-1 {
    animation: fadeOut 1s forwards;
}

.triangle-2 {
    transform: 1s;
}

.active.triangle-2 {
    animation: fadeOut 1s forwards;
}

.margin-bottom-title {
    margin-bottom: 20px;
}

/* media */
@media (max-width: 1400px) {
    .star-zoom {
        transform: scale(1.5) !important; /* Scale for smaller screens */
    }
}

@media (max-width: 1200px) {
    .star-zoom {
        transform: scale(1.3) !important; /* Scale for smaller screens */
    }
}

@media (max-width: 1024px) {
    .star-zoom {
        transform: scale(.9) !important; /* Scale for smaller screens */
    }
    .divas-container-background-image {
        background-position: center;
    }
}

@media (max-width: 992px) {
    .star-zoom {
        transform: scale(.8) !important; /* Scale for smaller screens */
    }
}

@media (max-width: 768px) {
    .star-zoom {
        transform: scale(.8) !important; /* Scale for smaller screens */
    }
}

@media (max-width: 576px) {
    .star-zoom {
        transform: scale(.7) !important; /* Scale for smaller screens */
    }

    .white-header {
        font-size: 16px !important;
        letter-spacing: 15px !important;
    }

    .white-content {
        font-size: 13px !important;
    }

    .text-white-spacing {
        font-size: .8rem;
        letter-spacing: 10px;
    }
}

.snap-container {
}
