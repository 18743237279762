@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@200..900&display=swap');

/* Hide the scrollbar for Chrome, Safari, and Edge */
html, body {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

:root {
  --radius: 1.5vw;
  --translate-y-start: 0; /* Starting translation */
  --translate-y-end: 10px; /* End translation (adjust as needed) */

}

@font-face {
  font-family: 'gen-ryu';
  src: url("./assets/fonts/GenRyuMin-M.ttc") format('truetype');
}

@font-face {
  font-family: 'gen-ryu-bold';
  src: url("./assets/fonts/GenRyuMin-B.ttc") format('truetype');
}

@font-face {
  font-family: 'gen-ryu-sb';
  src: url("./assets/fonts/GenRyuMin-SB.ttc") format('truetype');
}

@font-face {
  font-family: 'times-new';
  src: url("./assets/fonts/Times\ New\ Roman.ttf") format('truetype');
}

.gen-ryu {
  font-family: 'gen-ryu';
  color: white;
  font-weight: normal;
}

.gen-ryu-bold {
  font-family: 'gen-ryu-bold';
  color: white;
  font-weight: normal;
}

.gen-ryu-sb {
  font-family: 'gen-ryu-sb';
  color: white;
  font-weight: normal;
}

.times-new {
  font-family: 'times-new';
  color: white;
  font-weight: normal;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,  "Noto Serif TC",'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background-image {
  background-image: url("./assets/map.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.rotate-triangle-container {
 animation: rotate-scale 1s forwards;
}

.circle-container {
  position: relative;
  width: 60%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate-scale 5s infinite;
  transform-origin: 50% calc((50% + 1.5vh));
}

.circle-img {
  position: absolute;
  width: 16vw;
  height: 16vw;
  transition: 1s;
  transform-origin: 50% calc((50% + 1.5vh));
}

.rotate {
  animation: rotate-image 1s infinite;
}

.circle-img:nth-child(1) {
  /* transform: rotate(0deg) translate(0, -50%); */
  /* animation: rotate-image1 3s forwards; */
}

.active.circle-img:nth-child(2) {
  animation: rotate-image2 1s forwards;
  z-index: 4;
}

.active.circle-img:nth-child(3) {
  animation: rotate-image3 1s forwards;
  animation-delay: 1.1s;
  z-index: 3;
}

.active.circle-img:nth-child(4) {
  animation: rotate-image4 1s forwards;
  animation-delay: 2.1s;
  z-index: 2;
}

.active.circle-img:nth-child(5) {
  animation: rotate-image5 1s forwards;
  animation-delay: 3.1s;
  z-index: 1;
}

/* 
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.divas-imgs {
  transition: 1s;
}

.divas-img1 {
  transform: rotate(-72deg) translate(0, -50%);
  opacity: 0;
}

.active .divas-img1  {
  transform: rotate(0deg) translate(0, -50%);
}

.divas-img2  {
  transform: rotate(0deg) translate(0, -50%);
  opacity: 0;
  /* animation-delay: 1.6s; 
}

.active .divas-img2  {
  transform: rotate(72deg) translate(0, -50%);
  animation-delay: 2.4s;
}

.divas-img3  {
  transform: rotate(72deg) translate(0, -50%);
  opacity: 0;
}

.active .divas-img3  {
  transform: rotate(144deg) translate(0, -50%);
}

.divas-img4  {
  transform: rotate(144deg) translate(0, -50%);
  opacity: 0;
}

.active .divas-img4 {
  transform: rotate(216deg) translate(0, -50%);
}

.divas-img5 {
  transform: rotate(216deg) translate(0, -50%);
  opacity: 0;
}

.active .divas-img5  {
  transform: rotate(288deg) translate(0, -50%);
}

.rotate-here {
  animation: rotate-scale 5s infinite;
}
*/

@keyframes rotate-scale {
  0% {
    /* transform: rotate(0deg); */
    transform: rotate(0deg) scale(1);
  }
  100% {
    /* transform: rotate(360deg);  */
    transform: rotate(360deg) scale(2.5);
  }
}

@keyframes rotate-image1 {
  0% {
    transform: rotate(0deg) translate(0, 0%);
  }
  100% {
    transform: rotate(0deg) translate(0, -50%);
  }
}

@keyframes rotate-image2 {
  0% {
    transform: rotate(0deg) translate(0, -50%);
    opacity: 0;
  }
  100% {
    transform: rotate(72deg) translate(0, -50%);
    opacity: 1;
  }
}

@keyframes rotate-image3 {
  0% {
    transform: rotate(72deg) translate(0, -50%);
    opacity: 0;
  }
  100% {
    transform: rotate(144deg) translate(0, -50%);
    opacity: 1;
  }
}

@keyframes rotate-image4 {
  0% {
    transform: rotate(144deg) translate(0, -50%);
    opacity: 0;
  }
  100% {
    transform: rotate(216deg) translate(0, -50%);
    opacity: 1;
  }
}

@keyframes rotate-image5 {
  0% {
    transform: rotate(216deg) translate(0, -50%);
    opacity: 0;
  }
  100% {
    transform: rotate(288deg) translate(0, -50%);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0 !important;
  }
  100% {
    opacity: 1 !important;
  }
}


/* Rotate each image equally around the circle */
/* .circle-img:nth-child(1) {
  transform: rotate(0deg) translate(-50%, -50%);
}

.circle-img:nth-child(2) {
  transform: rotate(72deg) translate(-50%, -50%);
}

.circle-img:nth-child(3) {
  transform: rotate(144deg) translate(-50%, -50%);
}

.circle-img:nth-child(4) {
  transform: rotate(216deg) translate(-50%, -50%);
}

.circle-img:nth-child(5) {
  transform: rotate(288deg) translate(-50%, -50%);
} */

.star-images {
  opacity: 0;
}

.bulgari {
  transition: 1s;
}

.active.bulgari {
  animation: fadeIn 1s forwards;
}

.dolce {
  transition: 1s;
}

.active.dolce {
  animation: fadeIn 1s forwards;
}

.bulgari-dolce-container {
  transform: 1s;
}

.active.bulgari-dolce-container {
  animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.big-star {
  animation: scaleStar 3s forwards
}

.opacity-text {
  animation-delay: .5s;
  animation: fadeOut 2s forwards
}

@keyframes scaleStar {
  0% {
    transform: scale(200);
  }
  100% {
    transform: scale(1);
  }
}

